<template>
  <div class="content-wrap">
    <div class="content-select" v-loading="tableLoading">
      <trade-basic
        scene="BI"
        :cooRole="cooRole"
        :settlement="settlement"
        v-loading="settlementLoading"
      ></trade-basic>
    </div>
    <div class="content-main">
      <div class="content-table">
        <div class="table-select">
          <div class="select-title">
            <span>发票列表</span>
          </div>
          <div>
            <el-button
              type="primary"
              @click="handleQuery"
              v-hasPermi="['coo:trade-invoicing:list']"
              >查询</el-button
            >
            <el-button
              type="primary"
              :disabled="generateBtnDisabled"
              @click="handleGenerateInvoice"
              v-hasPermi="['coo:trade:generate-invoice']"
              >生成发票</el-button
            >
            <el-button
              v-if="cooRole == 'SS'"
              type="default"
              :disabled="gotoBillBtnDisabled"
              @click="handleGotoBillInvoice"
              v-hasPermi="['coo:trade-invoicing:redirect']"
              >立即开票</el-button
            >
            <el-button
              type="warning"
              :disabled="rescindBtnDisabled"
              @click="handleRescindInvoice"
              v-hasPermi="['coo:trade:rescind-invoice']"
              >撤销开票</el-button
            >
            <el-dropdown
              @command="handleMoreOperation"
              v-hasPermi="[
                'coo:trade-invoicing:export',
                'coo:trade-invoicing:import',
              ]"
            >
              <el-button type="default" style="width: 124px; margin-left: 10px"
                >更多操作<i class="el-icon-arrow-down el-icon--right"></i
              ></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :command="1"
                  v-hasPermi="['coo:trade-invoicing:export']"
                  >数电开票导出</el-dropdown-item
                >
                <el-dropdown-item
                  :command="2"
                  v-hasPermi="['coo:trade-invoicing:import']"
                  >数电发票导入</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="table-main">
          <el-table
            border
            @row-click="clickRow"
            :data="tableData"
            ref="previewTable"
            v-loading="tableLoading"
            highlight-current-row
            show-summary
            :summary-method="getTableSummary"
            @selection-change="handleSelection"
            :header-cell-style="handleHeaderCellStyle"
          >
            <el-table-column
              type="selection"
              width="55"
              fixed="left"
              align="center"
              header-align="center"
            />
            <el-table-column
              type="index"
              fixed="left"
              label="序号"
              width="80"
            />
            <el-table-column
              prop="salInvoice.billSerialNo"
              fixed="left"
              label="开票流水"
              min-width="200"
            />
            <el-table-column
              prop="salInvoice.billingType"
              label="开票类型"
              min-width="200"
            >
              <template slot-scope="scope">
                {{ fmtBillingType(scope.row.salInvoice.billingType) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="salInvoice.buyerName"
              label="购方名称"
              min-width="160"
            />
            <el-table-column
              prop="salInvoice.buyerTaxNo"
              label="购方税号"
              width="180"
            />
            <el-table-column
              prop="salInvoice.sellerName"
              label="销方名称"
              min-width="160"
            />
            <el-table-column
              prop="salInvoice.sellerTaxNo"
              label="销方税号"
              width="180"
            />
            <el-table-column
              prop="salInvoice.amount"
              label="发票金额"
              min-width="120"
              header-align="right"
              align="right"
              :formatter="fmtInvoiceAmount"
            />
            <el-table-column
              prop="salInvoice.taxAmount"
              label="发票税额"
              min-width="120"
              header-align="right"
              align="right"
              :formatter="fmtInvoiceAmount"
            />
            <el-table-column
              prop="salInvoice.sumAmount"
              label="价税合计"
              min-width="140"
              header-align="right"
              align="right"
              :formatter="fmtInvoiceAmount"
            />
            <el-table-column
              label="开票状态"
              width="100"
              fixed="right"
              header-align="center"
              align="center"
            >
              <template slot-scope="scope">
                <span>{{
                  handleValueToLabel(
                    "InvoiceStatus",
                    scope.row.salInvoice.invoiceStatus
                  )
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="operate"
              label="操作"
              width="80"
              header-align="center"
              align="center"
              fixed="right"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="handleInvoicePreview(scope.$index, scope.row)"
                >
                  预览
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="content-page">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="invoiceParam.pageNo"
          :page-sizes="pageSizes"
          :page-size="invoiceParam.pageSize"
          layout="total, sizes, prev, pager, next"
          :total="dataTotal"
        >
        </el-pagination>
      </div>
    </div>

    <preview-dialog
      :isLoading="invoiceDetailLoading"
      page
      ref="preview"
      :visible.sync="invoiceVisible"
      :invoice="invoice"
      :current="current"
      :total="totalPreview"
      @prev="handlePrev"
      @next="handleNext"
      @detail="handleDetail"
    ></preview-dialog>

    <el-dialog
      append-to-body
      title="全电开票结果导入"
      :visible.sync="isCenterDialogVisible"
      width="30%"
    >
      <span style="color: red; font-size: 17px; padding: 15px"
        >发票数据及状态是通过“发票流水号”进行关联更新的，请仔细核对后上传。</span
      >
      <el-form
        ref="form"
        :model="importForm"
        :rules="importRules"
        label-width="140px"
        style="text-align: left"
      >
        <el-form-item
          style="padding: 15px"
          label="开票结果导入："
          prop="fileName"
        >
          <el-upload
            accept=".xls,.xlsx"
            :action="uploadUrl"
            :multiple="false"
            :auto-upload="false"
            :show-file-list="false"
            :on-exceed="handleExceed"
            :on-change="handleUpload"
            style="display: inline-block"
          >
            <el-input
              v-model="importForm.fileName"
              placeholder="文件名称..."
              style="width: 300px"
            ></el-input>
            <el-button type="primary" plain icon="el-icon-upload2"
              >选择</el-button
            >
          </el-upload>

          <el-link style="font-size: 15px" type="primary" @click="previewImg"
            >文件下载路径指引
          </el-link>
        </el-form-item>
        <el-form-item style="padding: 15px" label="开票时间：" prop="date">
          <el-date-picker
            v-model="importForm.date"
            type="datetime"
            format="yyyy-MM-dd  HH:mm:ss"
            placeholder="选择日期时间："
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isCenterDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="rpaImport">确 定</el-button>
      </span>
    </el-dialog>

    <!-- <el-image
      ref="previewImage"
      style="width: 100px; height: 100px; display: none"
      :preview-src-list="invoiceImages"
    >
    </el-image> -->

    <generate-invoice
      ref="generateInvoiceRef"
      @success="handleQuery"
    ></generate-invoice>

    <rescind-invoice ref="rescindInvoiceRef" @success="handleQuery">
    </rescind-invoice>
  </div>
</template>

<script>
import { tableStyle, pagingMethods, changeMethods } from "@/util/mixins";
import {
  reqPagingSettlementSalInvoice,
  reqSelectSettlementSalInvoice,
  reqPagingSettlementSalInvoiceItem,
} from "@/service/coo/trade-sal-invoice.js";
import {
  downloadInvoice,
  exportAlreadyInvoices,
  rpaExport,
  rpaImport,
  importResultExport,
} from "@/service/settlement";
import { reqSelectSettlement } from "@/service/coo/trade.js";
import { fmtCurrency } from "@/assets/js/format-util";

import PreviewDialog from "../preview/PreviewDialog";
import { baseURL } from "@/service";
import TradeBasic from "@/views/cooperate/settlement/components/tradeBasic.vue";
import GenerateInvoice from "@/views/cooperate/settlement/components/invoicing/generateInvoice.vue";
import RescindInvoice from "@/views/cooperate/settlement/components/invoicing/rescindInvoice.vue";

export default {
  name: "SettlementInvoicingDetail",
  mixins: [tableStyle, changeMethods, pagingMethods],
  components: { TradeBasic, PreviewDialog, GenerateInvoice, RescindInvoice },
  props: {
    cooRole: {
      type: String,
      default: "SS",
    },
  },
  data() {
    return {
      dialogVisible: true,
      invoiceVisible: false,
      current: 1,
      totalPreview: 1,
      invoice: {
        items: [],
      },
      invoiceImages: [require("@/assets/img/guide/import-guide.png")],

      settlementId: this.$route.query.id,
      settlement: {},
      settlementLoading: false,
      // 列表条件
      invoiceParam: { oriTradeId: undefined, pageNo: 1, pageSize: 50 },
      // 开票列表
      dataTotal: 0,
      tableData: [],
      tableLoading: false,

      gotoBillVisible: false,

      selections: [],
      billingTradeIds: [],
      importForm: {
        fileName: undefined,
        date: undefined,
        file: undefined,
      },
      importRules: {
        fileName: [
          { required: true, message: "请选择导入文件", trigger: "blur" },
        ],
        date: [{ required: true, message: "请选择导入时间", trigger: "blur" }],
      },
      exportToastVisible: false,
      isCenterDialogVisible: false,
      // 导出条件
      exportParam: {
        ids: [],
        oriTradeId: 0,
      },
      uploadUrl: `${baseURL}/coo/settlement/trade/invoice/rpaImport`,
      invoiceDetailLoading: false,
    };
  },
  created() {
    this.$watch(
      () => this.$route.path,
      () => {
        this.handleQuery();
      }
    );

    this.handleQuery();
  },
  computed: {
    generateBtnDisabled() {
      return (
        !this.settlement.id ||
        this.settlement.sheetStage != "BI" ||
        ["DDCL", "CLSB", "BFKP"].indexOf(this.settlement.sheetStatus) == -1
      );
    },
    rescindBtnDisabled() {
      return (
        !this.settlement.id ||
        ["BI", "CS"].indexOf(this.settlement.sheetStage) == -1 ||
        this.selections.length < 1 ||
        this.selections.filter(
          (item) => ["WKP", "KJSB"].indexOf(item.salInvoice.invoiceStatus) == -1
        ).length > 0
      );
    },
    gotoBillBtnDisabled() {
      if (this.tableData.length < 1) {
        return true;
      }

      return this.settlement.billInvoiceStatus == "QB";
    },
  },
  methods: {
    // 切换分页条数
    handleSizeChange(value) {
      this.invoiceParam.pageNo = 1;
      this.invoiceParam.pageSize = value;
      this.pagingSettlementInvoicing();
    },
    // 开票列表翻页
    handleCurrentChange(value) {
      this.invoiceParam.pageNo = value;
      this.pagingSettlementInvoicing();
    },
    // 转到待开发票
    handleGotoBillInvoice() {
      let salInvoiceIds = this.tableData.map((item) => item.salInvoice.id);
      // console.log(JSON.stringify(salInvoiceIds));
      this.$router.push({
        path: "/open/issue",
        query: { salInvoiceIds: window.btoa(salInvoiceIds) },
      });
    },
    // 处理查询事件
    handleQuery() {
      this.queryTrade();
      this.pagingSettlementInvoicing();
    },
    // 格式发票类型
    fmtBillingType(billingType) {
      return this.handleValueToLabel("BillingType", billingType);
    },
    // 获取开票列表
    async pagingSettlementInvoicing() {
      this.tableLoading = true;
      this.invoiceParam.oriTradeId = this.settlementId;
      const { success, data } = await reqPagingSettlementSalInvoice(
        this.invoiceParam
      );
      this.tableLoading = false;
      if (success) {
        this.tableData = data.list;
        this.dataTotal = data.total;
        this.tableData.forEach((i) => {
          i.downDisabled = !(
            i.billingType == "Dzpp" && i.invoiceStatus == "YKJ"
          );
        });
      }
    },
    // 发票详情预览
    handleInvoicePreview(index, row) {
      this.invoiceVisible = true;
      this.invoicePreview(row);

      this.current = index + 1;
      this.totalPreview = this.tableData.length;
    },
    // 打开详情预览
    async invoicePreview(row) {
      this.invoiceDetailLoading = true;
      const { data } = await reqSelectSettlementSalInvoice(row.salInvoice.id);
      this.invoiceDetailLoading = false;
      this.invoice = data;
      this.invoice.hasSalesList = row.hasSalesList ? true : row.hasSalesList;
      this.invoice.hasSalesList = true;
    },
    // 发票预览翻页 上一页
    handlePrev() {
      this.current--;
      this.invoicePreview(this.tableData[this.current - 1]);
    },
    // 发票预览翻页 下一页
    handleNext() {
      this.current++;
      this.invoicePreview(this.tableData[this.current - 1]);
    },
    // 获取销货清单
    async handleDetail(page, size) {
      // 查询发票明细
      this.$refs.preview.closeLoading();
      const { success, data } = await reqPagingSettlementSalInvoiceItem({
        page,
        size,
        invoiceId: this.tableData[this.current - 1].salInvoice.id,
      });
      if (success) {
        this.invoice.items = data.records;
      }
    },
    //处理下载
    async handleInvoiceDownload(row) {
      const rsp = await downloadInvoice(row.salInvoice.billSerialNo);
      this.downloadFile(rsp);
    },
    // 勾选
    handleSelection(selections) {
      this.selections = selections;
    },
    // 处理生成发票
    handleGenerateInvoice() {
      if (!this.settlement.id) {
        this.toast("结算订单尚未加载完成！", "warning");
        return;
      }

      this.$refs.generateInvoiceRef.showDialog([this.settlement]);
    },
    // 处理撤销开票
    handleRescindInvoice() {
      if (!this.settlement.id) {
        this.toast("结算订单尚未加载完成！", "warning");
        return;
      }

      this.$refs.rescindInvoiceRef.showDialog(
        this.settlement,
        this.selections.map((item) => item.salInvoice)
      );
    },
    // 发票预览
    clickRow(row, column) {
      if (["操作"].indexOf(column.label) != -1) {
        return;
      }
      this.$refs.previewTable.toggleRowSelection(row);
    },
    // 导出
    async exportExcel() {
      this.exportToastVisible = false;
      this.exportDisabled = true;
      let items = this.selections;
      this.ids = items.map((item) => item.id);
      this.exportParam.colOptions = this.colOptions;
      //导出参数与 查询参数分割
      let param = this.exportParam;
      param.ids = this.ids;
      const rsp = await exportAlreadyInvoices(param);
      this.exportDisabled = false;
      this.downloadFile(rsp);
    },
    // 导出
    async rpaExport() {
      let total = 0;
      if (this.selections.length > 0) {
        let rows = this.selections.filter((item) => {
          item.salInvoice.billingType !== "QDZP" && item.billingType !== "QDPP";
        });
        if (rows.length > 0) {
          this.$confirm(
            "您选中或查询出来的数据中存在“发票类型非全电票”的数据，请检查后重新导出",
            "提示"
          );
          return;
        }
        total = this.selections.length;
        this.exportParam.ids = this.selections.map(
          (item) => item.salInvoice.id
        );
      } else {
        //当前查询条件全部
        total = this.page.total;
        this.exportParam.oriTradeId = this.settlementId;
      }
      // console.log("total-----------------", total);

      this.$confirm(
        "您是否要导出已选中或查询出来" + total + "条的数据？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          const { success } = rpaExport(this.exportParam).then((res) => {
            let blob = new Blob([res.data], { type: "application/xlsx" });
            let url = window.URL.createObjectURL(blob);
            const link = document.createElement("a"); // 创建a标签
            link.href = url;
            link.download = "全电导出模板.xlsx"; // 重命名文件
            link.click();
            URL.revokeObjectURL(url);
          });
          if (success) {
            this.toast("导出成功！", "success");
          }
          this.exportParam.ids = [];
        })
        .catch(() => {});
    },
    openFile() {
      document.getElementById("open").click();
    },

    previewImg() {
      this.$nextTick(() => {
        this.$refs.previewImage.clickHandler();
      });
    },
    handleExceed() {
      this.toast("导入失败，上传文件发生错误！", "error");
    },
    // 文件上传成功
    handleUpload(file) {
      this.importForm.fileName = file.name;
      this.importForm.file = file.raw;
    },
    changeFile() {
      const fu = document.getElementById("open");
      if (fu == null) return;
      this.importForm.fileName = fu.files[0].name;
      this.importForm.file = fu.files[0];
    },
    handleMoreOperation(type) {
      switch (type) {
        case 1:
          this.rpaExport();
          break;
        case 2:
          this.isCenterDialogVisible = true;
          break;
        default:
          break;
      }
    },
    async rpaImport() {
      if (this.importForm.fileName === "") {
        this.toast("请选择导入文件", "error");
        return;
      }
      if (this.importForm.date == "") {
        this.toast("请选择导入时间", "error");
        return;
      }
      this.isCenterDialogVisible = false;
      let formData = new FormData();
      console.log(this.importForm.file, "----- this.importForm.file---");
      formData.append("file", this.importForm.file);
      formData.append(
        "date",
        this.$moment(this.importForm.date).format("YYYY-MM-DD HH:mm:ss")
      );
      // console.log("formData-------------", formData);
      const { success, message, data } = await rpaImport(formData);
      if (success) {
        this.importForm = {
          fileName: undefined,
          date: undefined,
          file: undefined,
        };
        this.resultExport(data);
      }
    },
    resultExport(data) {
      this.$confirm("文件导入完成", "提示", {
        confirmButtonText: "下载导入结果",
        cancelButtonText: "取消",
      })
        .then(() => {
          const { success } = importResultExport(data).then((res) => {
            let blob = new Blob([res.data], { type: "application/xlsx" });
            let url = window.URL.createObjectURL(blob);
            const link = document.createElement("a"); // 创建a标签
            link.href = url;
            link.download = "全电开票导入结果.xlsx"; // 重命名文件
            link.click();
            URL.revokeObjectURL(url);
          });
        })
        .catch(() => {});
    },
    // 格式金额字段
    fmtInvoiceAmount(row, column, value) {
      return fmtCurrency(value, 2, "", ",", ".", "");
    },
    // 计算开票汇总
    getTableSummary(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index == 1) {
          sums[index] = "汇总";
        } else {
          let idx = [
            "salInvoice.amount",
            "salInvoice.taxAmount",
            "salInvoice.sumAmount",
          ].indexOf(`${column.property}`);
          if (idx == -1) {
            sums[index] = "";
          } else {
            const values = data.map((item) => {
              if (idx == 0) {
                return Number(item.salInvoice.amount);
              } else if (idx == 1) {
                return Number(item.salInvoice.taxAmount);
              } else {
                return Number(item.salInvoice.sumAmount);
              }
            });

            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + value;
                } else {
                  return prev;
                }
              }, 0);
              sums[index] = Number(sums[index]).toFixed(2) + " 元";
            } else {
              sums[index] = "";
            }
          }
        }
      });

      return sums;
    },
    // 查询结算订单
    async queryTrade() {
      this.settlementLoading = true;
      const { success, data } = await reqSelectSettlement(this.settlementId);
      if (success) {
        this.settlement = data;
      }
      this.settlementLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.content-select {
  box-sizing: border-box;
  padding: 20px;
}
.content-table {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // min-height: calc(100% - 200px);

  .table-statistics {
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: left;
    background: #ebf4ff;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 20px;

    ::v-deep div {
      margin-left: 25px;
    }
    ::v-deep div:first-child {
      margin-right: 35%;
    }
  }
}

.select-header {
  align-items: center;
  padding: 24px 24px;

  label {
    width: 80px;
    height: 22px;
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 22px;
  }

  span {
    width: 268px;
    height: 22px;
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
  }
}

.table-select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
  padding: 10px 0px 10px 0px;

  .item-absolute {
    position: absolute;
    right: 50%;
    top: 276px;
    padding-right: 24px;
  }

  .select-items {
    display: inline-flex;
  }
}

.el-table {
  ::v-deep .el-button {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.content-main {
  display: flex;
  padding: 10px 20px;
  min-height: calc(100% - 120px);
  flex-direction: column;
  justify-content: space-between;

  .content-table {
    .operate-button {
      display: flex;
      justify-content: center;

      .el-button {
        padding: 0;
        margin-right: 10px;
      }
    }
  }
}
::v-deep .el-dialog__body {
  padding-top: 0px;
  margin-top: 0px;
}
</style>
